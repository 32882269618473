import { Slot } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '~/utils/components.ts'

const buttonVariants = cva('inline-flex items-center justify-center rounded-xl hover:scale-105 font-medium ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50', {
	variants: {
		variant: {
			default: 'bg-primary text-white hover:bg-primary/90',
			destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
			outline: 'border border-input bg-transparent shadow-sm hover:bg-accent hover:text-accent-foreground',
			ghost: 'hover:bg-accent hover:text-accent-foreground',
			link: 'text-primary underline-offset-4 hover:underline',
			'primary-degrade': `text-white bg-gradient-to-r from-primary to-primary-degrade`,
			primary: `text-white bg-primary`,
			'primary-outline': `text-primary bg-white border border-primary`,
			'primary-reverse': `bg-white text-primary`,
			secondary: `text-white bg-secondary hover:bg-secondary/80`,
			'danger-outline': `hover:bg-danger hover:text-white border border-danger text-danger`,
			danger: `text-white bg-danger hover:bg-danger/80`,
			warning: `text-white bg-warning hover:bg-warning/80`,
			'warning-outline': `text-warning bg-white border border-warning hover:bg-warning hover:bg-white`,
			'cancel-outline': `hover:bg-slate-400 hover:text-white bg-white border border-slate-400`,
			'slate-light': ` hover:bg-slate-200 border border-slate-300 bg-white text-slate-700`,
			cancel: `bg-slate-400 text-white hover:bg-slate-300`,
			'accent-magenta': `bg-accent-magenta text-white hover:bg-accent-magenta/80`,
		},
		size: {
			default: 'px-4 py-2',
			wide: 'px-24 py-5',
			sm: 'h-9 px-3',
			base: 'px-6 h-9',
			lg: 'h-11 px-8',
			pill: 'px-12 py-2 leading-3',
			icon: 'h-10 w-10',

		},
	},
	defaultVariants: {
		variant: 'default',
		size: 'default',
	},
})

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
	asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
	const Comp = asChild ? Slot : 'button'
	return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />
})
Button.displayName = 'Button'

export { Button, buttonVariants }
